import React from 'react';
import '../App.css';
import { motion } from 'framer-motion';



const HomePageButton = ({ onClick }) => {
  return (
    <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: "15vh"
      }}>
    <motion.button type="button" onClick={onClick} className="button-lore"
    whileHover={{scale:1.1,opacity:0.4}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="#878787"
        className="bi bi-arrow-down-circle"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"
        />
      </svg>
    </motion.button>
    </div>
  );
};

export default HomePageButton;